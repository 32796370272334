import "./WorkExperience.scss";

const WorkExperience = () => {
  return (
    <div className="timeline experience">
      <div className="row">
        <div className="col-md-12">
          <div className="exp-holder">
          <div className="exp">
              <div className="hgroup">
                <h4>
                  <span>Senior Python Engineer</span> / Corner Case Technologies
                </h4>
                <h5 className="badge">Sep 2024 - <span className="current">Present</span></h5>
              </div>
              <p>
                Currently, I'm specializing as a Senior Software Engineer at Corner Case Technologies. Specifically, working with <b>Python, Microservices and IoT</b> technologies.
              </p>
            </div>
            <div className="exp">
              <div className="hgroup">
                <h4>
                  <span>Full-Stack Engineer</span> / Freelance
                </h4>
                <div className="badges">
                  <h5 className="badge">Mar 2023 - Sep 2024</h5>
                </div>
              </div>
              <p>
                As a Freelancer, I've worked independently and collaboratively on diverse projects, leveraging my
                expertise in <b>Django</b>, <b>.NET</b>, <b>React</b>, and other cutting-edge technologies to develop custom solutions for
                unique business needs. Engaging with various industries, I've tackled complex technical challenges,
                delivering top-notch results and ensuring client satisfaction. Embracing the freedom and flexibility of
                freelancing, I've continuously honed my skills, building a strong portfolio and establishing a
                reputation as a reliable and adaptable software engineer.
              </p>
            </div>
            <div className="exp">
              <div className="hgroup">
                <h4>
                  <span>Senior Software Engineer</span> / Reorg
                </h4>
                <h5 className="badge">Feb 2021 - Mar 2023</h5>
              </div>
              <p>
                As a Senior Software Engineer at Reorg, a company that acquired Aggredium, I took on a leadership role
                in guiding a talented team of software developers. Leveraging my expertise in <b>Django</b> and <b>.NET</b>, I led the
                design and implementation of modern solutions to address complex challenges. Amidst the company's recent
                change in investors and the significant market value surpassing{" "}
                <a
                  href="https://www.reuters.com/markets/us/permira-nears-13-bln-deal-acquire-reorg-sources-2022-07-29/"
                  target="_blank"
                  rel="noreferrer"
                >
                  $1.3billion
                </a>
                , I played a pivotal role in driving innovation and growth, contributing to the organization's
                remarkable success.
              </p>
            </div>
            <div className="exp">
              <div className="hgroup">
                <h4>
                  <span>Software Engineer</span> / Aggredium
                </h4>
                <h5 className="badge">Feb 2019 - Feb 2021</h5>
              </div>
              <p>
                As a Software Engineer with expertise in <b>Django</b> and <b>.NET</b>, I collaborated with a team of
                developers at a dynamic startup. I played a crucial role in architecting and developing high-performance
                web applications, ensuring scalability and maintainability. The startup{" "}
                <a
                  href="https://www.prnewswire.co.uk/news-releases/reorg-acquires-aggredium-887977367.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  was acquired
                </a>{" "}
                by Reorg.
              </p>
            </div>
            <div className="exp">
              <div className="hgroup">
                <h4>
                  <span>Software Engineer</span> / Freelance
                </h4>
                <h5 className="badge">Jan 2016 - Feb 2019</h5>
              </div>
              <p>
                As a beginner engineer, I started with backend focus growth: <b>.NET Framework, MSSQL, WinForms, WPF</b>
                . I worked on various business process optimization projects, such as employee management, business
                process automation, web scraping for leads. During this period, I've built a strong foundation for
                problem solving, data structures, data modeling, and best programming practices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;
